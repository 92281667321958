@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  overflow: hidden;
}

input[type="color"]::-webkit-color-swatch {
  background: transparent;
  border: 0;
}

* {
  box-sizing: border-box;
}

a {
  color: white !important;
  text-decoration: none !important;
}

body {
  margin: 0;
  background-color: whitesmoke;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  justify-content: center;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #273c63 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.vnicornMeme {
  position: relative;
  width: 100%;
  margin: auto;
}

.vnicornMeme > h2 {
  position: absolute;
  width: 80%;
  height: 200px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  margin: 15px 0;
  padding: 0 5px;
  font-family: impact, sans-serif;
  font-size: 2em;
  text-transform: uppercase;
  color: black;
  letter-spacing: 1px;
  text-shadow: 2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000,
    -2px 2px 0 #000, 0 2px 0 #000, 2px 0 0 #000, 0 -2px 0 #000, -2px 0 0 #000,
    2px 2px 5px #000;
}
.author {
  font-family: impact, sans-serif;
  font-size: 1em;
  text-transform: uppercase;
  color: white;
  letter-spacing: 1px;
  text-shadow: 2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000,
    -2px 2px 0 #000, 0 2px 0 #000, 2px 0 0 #000, 0 -2px 0 #000, -2px 0 0 #000,
    2px 2px 5px #000;
}

@media (max-width: 767px) {
  .vnicornMeme > h2 {
    font-size: 1em;
  }
}

.vnicornMeme > i {
  position: absolute;
  left: 0%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.7em;
  color: white;
  background: #00000078;
  padding: 5px;
  margin: 5px;
  border-radius: 2px;
}

.vnicornMeme > .bottomText {
  bottom: 0;
}

.vnicornMeme > .topText {
  top: 0;
}

.vnicornMeme > .authorText {
  bottom: 0;
  float: left;
}

.wrapper {
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, #04fafd, 5%, #119dff, 50%, #030423);
  position: absolute;
}
.wrapper h1 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  font-family: sans-serif;
  letter-spacing: 1px;
  word-spacing: 2px;
  color: #fff;
  font-size: 40px;
  font-weight: 888;
  text-transform: uppercase;
}
.wrapper div {
  height: 20px;
  width: 20px;
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 50px;
  position: absolute;
  top: 10%;
  left: 10%;
  animation: 4s linear infinite;
}
div .dot {
  height: 10px;
  width: 10px;
  border-radius: 50px;
  background: rgb(163, 154, 19);
  position: absolute;
  top: 20%;
  right: 20%;
}
.wrapper div:nth-child(1) {
  top: 20%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.wrapper div:nth-child(2) {
  top: 60%;
  left: 80%;
  animation: animate 10s linear infinite;
}
.wrapper div:nth-child(3) {
  top: 40%;
  left: 40%;
  animation: animate 3s linear infinite;
}
.wrapper div:nth-child(4) {
  top: 66%;
  left: 30%;
  animation: animate 7s linear infinite;
}
.wrapper div:nth-child(5) {
  top: 90%;
  left: 10%;
  animation: animate 9s linear infinite;
}
.wrapper div:nth-child(6) {
  top: 30%;
  left: 60%;
  animation: animate 5s linear infinite;
}
.wrapper div:nth-child(7) {
  top: 70%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.wrapper div:nth-child(8) {
  top: 75%;
  left: 60%;
  animation: animate 10s linear infinite;
}
.wrapper div:nth-child(9) {
  top: 50%;
  left: 50%;
  animation: animate 6s linear infinite;
}
.wrapper div:nth-child(10) {
  top: 45%;
  left: 20%;
  animation: animate 10s linear infinite;
}
.wrapper div:nth-child(11) {
  top: 10%;
  left: 90%;
  animation: animate 9s linear infinite;
}
.wrapper div:nth-child(12) {
  top: 20%;
  left: 70%;
  animation: animate 7s linear infinite;
}
.wrapper div:nth-child(13) {
  top: 20%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.wrapper div:nth-child(14) {
  top: 60%;
  left: 5%;
  animation: animate 6s linear infinite;
}
.wrapper div:nth-child(15) {
  top: 90%;
  left: 80%;
  animation: animate 9s linear infinite;
}
@keyframes animate {
  0% {
    transform: scale(0) translateY(0) rotate(70deg);
  }
  100% {
    transform: scale(1.3) translateY(-100px) rotate(360deg);
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgb(7, 228, 180);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/* === Root */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "montserrat regular", sans-serif;
}

/* === body */
body {
  overflow-y: hidden;
}
h2 {
  width: 35%;
  text-align: center;
  border-bottom: 1px solid rgb(219, 219, 219);
  line-height: 0.1em;
  margin: 10px 0 20px;
  text-transform: uppercase;
  font-size: 15px;
}

h2 span {
  background: #fff;
  padding: 0 10px;
}

h4::selection {
  background-color: #f87171;
  color: white;
}

/* Media queries */

@media screen and (max-width: 991px) {
  h2 {
    width: 50%;
  }
}

@media screen and (max-width: 425px) {
  h2 {
    width: 80%;
  }
}
@keyframes animate-bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.animate-bounce {
  animation: animate-bounce 1s ease-in-out infinite;
}
.scrollable-y {
  height: 100%;
  overflow-y: auto;
}
.parent-container {
  overflow-x: hidden;
}
.tables {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
  min-width: fit-content;
  font-size: 0.875rem;
}
.tables caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}
.tables tr:nth-child(even) {
  background-color: #f2f2f2;
}
.tables tr {
  /* background-color: #e5e7eb; */
  padding: 0.35em;
}

.tables th,
.tables td {
  padding: 0.725em;
  padding-left: 20px;
  text-align: left;
  border-bottom: 0px solid gray;
}

.tables th {
  font-size: 0.99em;
  letter-spacing: 0.1em;
  background-color: #273c63;
  color: #fff;
  cursor: pointer;
}

.sticky-header {
  position: sticky;
  top: -0.5px;
}

/* Responsive Table design for Aggregation Insights on Control Analysis */
.rtables {
  border: 1px solid #ddd;
  width: 70%;
}

.rtables caption {
  font-size: 1.3em;
}

.rtables thead {
  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
/*
  .rtables tr {
    border-bottom: 2px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }
*/
.rtables td {
  border-bottom: 1px solid #ddd;
  display: block;
  font-size: 0.8em;
  text-align: right;
  padding: 5px;
}

.rtables td::before {
  content: attr(data-label);
  float: left;
  font-weight: bold;
}

.rtables td:last-child {
  border-bottom: 0;
}
/* ------- */
.modalBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  margin-left: 1000px;
  margin-bottom: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  padding-bottom: 400px;
}
.modalContainer {
  z-index: 100;
  width: 900px;
  /* height: 600px; */
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}
.color_id {
  color: #286091;
}
.paginationBttns {
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #ce9f2c;
  color: #ce9f2c;
  background-color: #ce9f2c;
  cursor: pointer;
}

.paginationBttns a:hover {
  color: white !important;
  background-color: #ce9f2c;
}

.paginationActive a {
  color: white !important;
  background-color: #273c63;
  opacity: 0.9;
  cursor: default;
}

.paginationActive a:hover {
  color: white !important;
  background-color: #273c63;
  opacity: 0.9;
}

.paginationDisabled a {
  color: green;
  background-color: grey;
  opacity: 0.4;
}

.paginationDisabled a:hover {
  color: white;
  background-color: grey;
  opacity: 0.4;
  cursor: default;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
.image-container {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}